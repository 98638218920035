// External libraries
import * as React from "react";
import { inject, observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

// UITK
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";

// Components
import { LocationField } from "components/shared/LocationField/LocationField";
import { ACTION_DONE, getTracker } from "utils/TrackingUtils";
import {
  CarWizardState,
  FlightType,
  FlightWizardState,
  LOBState,
  LxWizardState,
  PackageWizardState,
} from "stores/wizard/state";
import { DefaultLocation } from "./components/DefaultLocation";
import { LocationList } from "./components/LocationList";
import { LocationType } from "./components/LocationType";
import { SwapLocations } from "../SwapLocations/SwapLocations";
import { LocationProps } from "./typings";
import { LocationFieldConfig } from "stores/wizard/config";
import { Location as LocationStateType } from "stores/wizard/state/global/location/typings";
import { useEffect } from "react";

enum LocationFieldType {
  STOPOVER_DEFAULT_LOCATION = "stopoverDefaultLocation",
  STOPOVER_LOCATION_LIST = "stopoverLocationList",
  ORIGIN_DEFAULT_LOCATION = "originDefaultLocation",
  ORIGIN_LOCATION_LIST = "originLocationList",
  DESTINATION_DEFAULT_LOCATION = "destinationDefaultLocation",
  DESTINATION_LOCATION_LIST = "destinationLocationList",
  STANDARD = "standard",
}

const getLocationInputName = (lobState: LOBState, location: LocationStateType, config: LocationFieldConfig) => {
  if (
    // For Flights, ignore the search term from the typeahead
    lobState instanceof FlightWizardState ||
    (lobState instanceof PackageWizardState && lobState.isMulticityFlight()) ||
    // When current location is selected, ignore the search term from the typeahead
    (config.inputName === config.currentLocationInputName && location.metaData.isCurrentLocation)
  ) {
    return "";
  }

  return config.inputName;
};

export const Location: React.FunctionComponent<LocationProps> = inject(
  "context",
  "analytics",
  "page"
)(
  observer(
    ({
      analytics,
      colSpan,
      colStart,
      colEnd,
      context,
      destinationField,
      globalConfig,
      lobState,
      multiCityFlight,
      multiLegIndex,
      onewayFlight,
      originField,
      setInputsRefs,
      showSwapLocationsControl,
      page,
      stopoverOrigin,
      stopoverDestination,
      stopoverMultipleOrigin,
      stopoverMultipleDestination,
      shouldHideHiddenInputs,
      originIcon,
      destinationIcon,
    }) => {
      if (!context) {
        // Check for injected stores
        return null;
      }

      const { DESTINATION, ORIGIN } = LocationType;
      const useLocationConfiguration = (index: number | undefined, state: LOBState) => {
        if (multiCityFlight && state.config.multiCityFlight.location) {
          return state.config.multiCityFlight.location; // multicity first leg
        }

        if (
          index !== undefined &&
          state.config.multiCityFlight &&
          (state instanceof FlightWizardState || state instanceof PackageWizardState)
        ) {
          return state.config.multiCityFlight.legs[index].location; // multicity additional legs
        }

        if (state instanceof PackageWizardState && onewayFlight && state.config.onewayFlight?.location) {
          return state.config.onewayFlight.location; // oneway flight
        }

        if (state instanceof LxWizardState) {
          return state.config.location;
        }

        return state.tabSelected === false
          ? state.subLOBState === "toAirport"
            ? state.config.gtConfig.toAirportLocation
            : state.config.gtConfig.location
          : state.config.location;
      };

      const locationConfig = useLocationConfiguration(multiLegIndex, lobState);
      const [isCarsDestinationPage, setIsCarsDestinationPage] = React.useState(false);

      const locationState =
        multiLegIndex !== undefined &&
        (lobState instanceof FlightWizardState || lobState instanceof PackageWizardState) &&
        lobState.listOfAdditionalLegs
          ? lobState.listOfAdditionalLegs[multiLegIndex].location
          : lobState.location;

      const { formatText } = useLocalization();

      // TODO: Consolidate functions doing related logic.

      const onOriginLocationFieldUpdate = (selection: TypeaheadSelection) => {
        if (lobState.updateOriginSelection) {
          lobState.updateOriginSelection(selection, multiLegIndex);
        }

        tracker.trackEvent(_origin + ACTION_DONE);
      };

      const onDestinationLocationFieldUpdate = (selection: TypeaheadSelection) => {
        lobState.updateDestinationSelection(selection, multiLegIndex);

        tracker.trackEvent(_destination + ACTION_DONE);
      };

      const getDestinationLabelToken = () => {
        const { withValueLabelToken, noValueLabelToken, labelToken } = locationConfig.destination;

        return lobState instanceof CarWizardState && lobState.isDesktop
          ? destinationValue
            ? withValueLabelToken
            : noValueLabelToken
          : labelToken;
      };

      const swapOriginAndDestination = () => {
        if (lobState.swapLocations) {
          lobState.swapLocations(multiLegIndex);
        }
      };

      const originInvalidMessage = lobState.originInvalidKey ? formatText(lobState.originInvalidKey) : "";
      const destinationInvalidMessage = lobState.destinationInvalidKey
        ? formatText(lobState.destinationInvalidKey)
        : "";

      const locationClassWithSwap = showSwapLocationsControl ? "locationWithSwap" : "";
      const locationFieldCols = originField && destinationField ? 1 : 2;

      locationState.origin.value = locationState.origin.value || "";
      locationState.destination.value = locationState.destination.value || "";

      const tracker = getTracker(lobState.trackingEnabled, analytics, lobState.moduleName);
      const destinationValue = locationState.destination.value;

      const _origin = "origin.";
      const _destination = "destination.";
      const destinationLabelToken = getDestinationLabelToken();

      const { defaultOrigin, defaultMultipleOrigin, defaultDestination, defaultMultipleDestination } =
        globalConfig || {};

      const defaultDestinationValue = lobState instanceof CarWizardState ? "" : defaultDestination;
      const defaultOriginValue = lobState instanceof CarWizardState ? defaultDestination : defaultOrigin;
      const defaultMultipleDestinationValue = lobState instanceof CarWizardState ? "" : defaultMultipleDestination;
      const defaultMultipleOriginValue =
        lobState instanceof CarWizardState ? defaultMultipleDestination : defaultMultipleOrigin;

      const buildMockTypeaheadSelection = (term: string): TypeaheadSelection => {
        const typeaheadMockResponse = {
          data: {
            selected: "",
            regionId: "",
            originId: "",
            cityId: "",
            type: "",
            hierarchyInfo: {
              airport: {
                airportCode: "",
                airportId: "",
                metrocode: "",
                multicity: "",
              },
              country: {
                name: "",
                isoCode2: "",
                isoCode3: "",
              },
            },
            location: {
              lat: "",
              long: "",
            },
            regionNames: {
              shortName: "",
            },
          },
          term,
        };

        return typeaheadMockResponse;
      };

      const multiCtyFlight = lobState.subLOBState === FlightType.MULTI_CITY;
      const getOriginFieldType = () => {
        if (stopoverOrigin && lobState.subLOBState === FlightType.MULTI_CITY)
          return LocationFieldType.STOPOVER_DEFAULT_LOCATION;
        if (stopoverMultipleOrigin && lobState.subLOBState === FlightType.MULTI_CITY)
          return LocationFieldType.STOPOVER_LOCATION_LIST;
        if (defaultOriginValue && lobState.subLOBState !== FlightType.MULTI_CITY)
          return LocationFieldType.ORIGIN_DEFAULT_LOCATION;
        if (defaultMultipleOriginValue && lobState.subLOBState !== FlightType.MULTI_CITY)
          return LocationFieldType.ORIGIN_LOCATION_LIST;
        return LocationFieldType.STANDARD;
      };

      const getDestinationFieldType = () => {
        if (stopoverDestination && lobState.subLOBState === FlightType.MULTI_CITY)
          return LocationFieldType.STOPOVER_DEFAULT_LOCATION;
        if (stopoverMultipleDestination && lobState.subLOBState === FlightType.MULTI_CITY)
          return LocationFieldType.STOPOVER_LOCATION_LIST;
        if (defaultDestinationValue && lobState.subLOBState !== FlightType.MULTI_CITY)
          return LocationFieldType.DESTINATION_DEFAULT_LOCATION;
        if (defaultMultipleDestinationValue && lobState.subLOBState !== FlightType.MULTI_CITY)
          return LocationFieldType.DESTINATION_LOCATION_LIST;
        return LocationFieldType.STANDARD;
      };

      /*
       * When user coming on landing page by searching for particular location, in that case we would be having
       * source location known. So We will prefilled Pick-up location for user when page load exclude for
       * location types: {country, continent, province_state} for car lob.
       */
      useEffect(() => {
        if (
          !locationState?.origin?.value &&
          context?.searchContext?.location !== null &&
          context.searchContext.lob === "car" &&
          context?.searchContext?.location.type != "country" &&
          context?.searchContext?.location.type != "continent" &&
          context?.searchContext?.location.type != "province_state"
        ) {
          const selectedLocation = context.searchContext.location;
          const selectedObj = {
            term: selectedLocation.largeName,
            data: {
              location: {
                lat: selectedLocation.parent.geoCoordinate.latitude,
                long: selectedLocation.parent.geoCoordinate.longitude,
              },
              regionId: selectedLocation.id,
              regionNames: {
                fullName: selectedLocation.extendedName,
                shortName: selectedLocation.name,
                displayName: selectedLocation.extendedName,
                primaryDisplayName: selectedLocation.largeName,
                secondaryDisplayName: selectedLocation.name,
              },
              type: selectedLocation.type.toUpperCase(),
              hierarchyInfo: {
                country: {},
                airport: {
                  airportCode: selectedLocation.code,
                  airportId: selectedLocation.id,
                  multicity: selectedLocation.parentId,
                },
              },
              essId: {
                sourceId: selectedLocation.id,
                sourceName: "GAI",
              },
              dest: true,
              index: 1,
            },
          };
          onOriginLocationFieldUpdate(selectedObj);
          setIsCarsDestinationPage(true);
        }
      }, []);

      const openAutoSuggestionBox = (inputsRef: React.RefObject<HTMLInputElement>) => {
        if (setInputsRefs) {
          setInputsRefs(inputsRef);
        }

        if (isCarsDestinationPage && !context.deviceInformation?.mobile) {
          setTimeout(() => {
            if (inputsRef.current) {
              inputsRef.current.click();
            }
          }, 0);
        }
      };

      const renderOrigin = () => {
        const fieldType = getOriginFieldType();
        switch (fieldType) {
          case LocationFieldType.STOPOVER_DEFAULT_LOCATION:
            return (
              <DefaultLocation
                name={getLocationInputName(lobState, lobState.location.origin, locationConfig.origin)}
                lobState={lobState}
                defaultLocation={stopoverOrigin || ""}
                locationType={ORIGIN}
                locationConfig={locationConfig}
                biasedStopover={stopoverOrigin}
              />
            );
          case LocationFieldType.STOPOVER_LOCATION_LIST:
            const onBiasedSelectChange = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
              if (lobState.updateOriginSelection) {
                lobState.updateOriginSelection(
                  buildMockTypeaheadSelection(selectEvent.currentTarget.value),
                  multiLegIndex
                );
              }
            };

            return (
              <LocationList
                name={getLocationInputName(lobState, lobState.location.origin, locationConfig.origin)}
                lobState={lobState}
                tracker={tracker}
                locationList={stopoverMultipleOrigin || []}
                locationType={ORIGIN}
                locationConfig={locationConfig}
                biasedStopover={stopoverMultipleOrigin}
                onBiasedSelectChange={onBiasedSelectChange}
                multiLegIndex={multiLegIndex}
              />
            );

          case LocationFieldType.ORIGIN_DEFAULT_LOCATION:
            return (
              <DefaultLocation
                name={getLocationInputName(lobState, lobState.location.origin, locationConfig.origin)}
                lobState={lobState}
                defaultLocation={defaultOriginValue || ""}
                locationType={ORIGIN}
                locationConfig={locationConfig}
              />
            );

          case LocationFieldType.ORIGIN_LOCATION_LIST:
            return (
              <LocationList
                name={getLocationInputName(lobState, lobState.location.origin, locationConfig.origin)}
                lobState={lobState}
                tracker={tracker}
                locationList={defaultMultipleOriginValue || []}
                locationType={ORIGIN}
                locationConfig={locationConfig}
                onBiasedSelectChange={undefined}
                multiLegIndex={multiLegIndex}
              />
            );

          case LocationFieldType.STANDARD:
            return (
              <LocationField
                setInputsRefs={openAutoSuggestionBox}
                context={context}
                onUpdate={onOriginLocationFieldUpdate}
                icon={originIcon ?? locationConfig.origin.icon}
                name={getLocationInputName(lobState, lobState.location.origin, locationConfig.origin)}
                subLob={lobState.subLOBState}
                label={formatText(locationConfig.origin.labelToken)}
                typeaheadPlaceholder={formatText(locationConfig.origin.typeaheadLabelToken)}
                typeaheadEmptyResultPlaceholder={formatText(locationConfig.origin.typeaheadEmptyResultPlaceholder)}
                invalid={originInvalidMessage}
                value={locationState.origin.value}
                essAdapterConfig={locationConfig.origin.essAdapterConfig}
                stid={`location-field-${locationConfig.origin.inputName}`}
                config={locationConfig.origin}
                location={locationState.origin}
                globalConfig={globalConfig}
                multiFlight={multiCtyFlight}
              />
            );

          default:
            return null;
        }
      };

      const renderDestination = () => {
        const fieldType = getDestinationFieldType();

        switch (fieldType) {
          case LocationFieldType.STOPOVER_DEFAULT_LOCATION:
            return (
              <DefaultLocation
                name={getLocationInputName(lobState, lobState.location.destination, locationConfig.destination)}
                lobState={lobState}
                defaultLocation={stopoverDestination || ""}
                locationType={DESTINATION}
                locationConfig={locationConfig}
                biasedStopover={stopoverDestination}
              />
            );
          case LocationFieldType.STOPOVER_LOCATION_LIST:
            const onBiasedSelectChange = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
              if (lobState.updateDestinationSelection) {
                lobState.updateDestinationSelection(
                  buildMockTypeaheadSelection(selectEvent.currentTarget.value),
                  undefined
                );
              }
            };

            return (
              <LocationList
                name={getLocationInputName(lobState, lobState.location.destination, locationConfig.destination)}
                lobState={lobState}
                tracker={tracker}
                locationList={stopoverMultipleDestination || []}
                locationType={DESTINATION}
                locationConfig={locationConfig}
                biasedStopover={stopoverMultipleDestination}
                onBiasedSelectChange={onBiasedSelectChange}
              />
            );

          case LocationFieldType.DESTINATION_DEFAULT_LOCATION:
            return (
              <DefaultLocation
                name={getLocationInputName(lobState, lobState.location.destination, locationConfig.destination)}
                lobState={lobState}
                defaultLocation={defaultDestinationValue || ""}
                locationType={DESTINATION}
                locationConfig={locationConfig}
                biasedStopover={stopoverDestination}
              />
            );

          case LocationFieldType.DESTINATION_LOCATION_LIST:
            const onDestinationSelectChange =
              lobState.subLOBState === FlightType.MULTI_CITY
                ? (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
                    if (lobState.updateDestinationSelection) {
                      lobState.updateDestinationSelection(
                        buildMockTypeaheadSelection(selectEvent.currentTarget.value),
                        0
                      );
                    }
                  }
                : undefined;

            return (
              <LocationList
                name={getLocationInputName(lobState, lobState.location.destination, locationConfig.destination)}
                lobState={lobState}
                tracker={tracker}
                locationList={defaultMultipleDestinationValue || []}
                locationType={DESTINATION}
                locationConfig={locationConfig}
                onBiasedSelectChange={onDestinationSelectChange}
              />
            );

          case LocationFieldType.STANDARD:
            return (
              <LocationField
                config={locationConfig.destination}
                context={context}
                essAdapterConfig={locationConfig.destination.essAdapterConfig}
                icon={destinationIcon ?? locationConfig.destination.icon}
                invalid={destinationInvalidMessage}
                label={formatText(destinationLabelToken)}
                location={locationState.destination}
                name={getLocationInputName(lobState, lobState.location.destination, locationConfig.destination)}
                subLob={lobState.subLOBState}
                onUpdate={onDestinationLocationFieldUpdate}
                setInputsRefs={setInputsRefs}
                stid={`location-field-${locationConfig.destination.inputName}`}
                typeaheadPlaceholder={formatText(locationConfig.destination.typeaheadLabelToken)}
                typeaheadEmptyResultPlaceholder={formatText(locationConfig.destination.typeaheadEmptyResultPlaceholder)}
                value={destinationValue}
                shouldHideHiddenInputs={shouldHideHiddenInputs}
              />
            );

          default:
            return null;
        }
      };

      return (
        <EGDSLayoutGridItem colSpan={colSpan} colStart={colStart} colEnd={colEnd}>
          <div>
            <EGDSLayoutGrid
              columns={{ small: 1, medium: 2 }}
              space="three"
              className={`Location ${locationClassWithSwap}`}
            >
              {originField && locationConfig.origin && (
                <EGDSLayoutGridItem colSpan={{ small: 1, medium: locationFieldCols }}>
                  <div>{renderOrigin()}</div>
                </EGDSLayoutGridItem>
              )}
              {showSwapLocationsControl && <SwapLocations swapLocations={swapOriginAndDestination} />}
              {destinationField && locationConfig.destination && (
                <EGDSLayoutGridItem colSpan={{ small: 1, medium: locationFieldCols }}>
                  <div>{renderDestination()}</div>
                </EGDSLayoutGridItem>
              )}
            </EGDSLayoutGrid>
          </div>
        </EGDSLayoutGridItem>
      );
    }
  )
);
